exports.linkResolver = (doc) => {
  if (doc.type === 'page') {
    return `/${doc.uid}/`
  }

  if (doc.type === 'case') {
    return `/cases/${doc.uid}/`
  }

  return '/'
}
